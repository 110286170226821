import {
  Anchor,
  AppShell,
  BackgroundImage,
  Box,
  Burger,
  Button,
  Container,
  Flex,
  Group,
  Header,
  Image,
  Menu,
  Paper,
  Text,
  Transition,
  createStyles,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link, useNavigate } from "@remix-run/react";
import { IconCaretDown } from "@tabler/icons-react";
import type { PropsWithChildren } from "react";
import { useState } from "react";

const HEADER_HEIGHT = 100;

type LinkType = {
  label: string;
} & ({ url: string; external?: boolean } | { children: LinkType[] });

const links = {
  header: [
    {
      label: "Home",
      url: "https://designerstrust.com/",
      external: true,
    },
    {
      label: "Designers Trust",
      children: [
        {
          label: "Why Designers Trust",
          url: "https://designerstrust.com/why-designers-trust/",
          external: true,
        },
        {
          label: "Our Designers",
          url: "/our-designers",
          external: false,
        },
        {
          label: "Our process",
          url: "https://designerstrust.com/our-process",
          external: true,
        },
        {
          label: "Our people",
          url: "https://designerstrust.com/our-people/",
          external: true,
        },
      ],
    },

    {
      label: "For Brands",
      url: "https://designerstrust.com/brands/",
      external: true,
    },
    {
      label: "For Designers",
      url: "https://designerstrust.com/designers/",
      external: true,
    },
    {
      label: "How it works",
      children: [
        {
          label: "For Brands",
          url: "https://designerstrust.com/how-it-works-brands/ ",
          external: true,
        },
        {
          label: "For Designers",
          url: "https://designerstrust.com/how-it-works-designers/ ",
          external: true,
        },
      ],
    },
    {
      label: "Contact",
      url: "https://designerstrust.com/contact/",
      external: true,
    },
  ],
  footer: [
    {
      label: "Timothy Jacob Jensen Studios",
      url: "https://timothyjacobjensen.com/",
    },
    {
      label: "Cookies",
      url: "https://designerstrust.com/cookies/",
    },
    {
      label: "Privacy policy",
      url: "https://designerstrust.com/privacy-policy/",
    },
    {
      label: "Terms and conditions",
      url: "https://designerstrust.com/terms-conditions/",
    },
  ],
} satisfies Record<"header" | "footer", LinkType[]>;

let flatHeaderLinks: {
  label: string;
  url?: string;
  isDropdown?: true;
  external?: boolean;
  isNested?: true;
}[] = [];

links.header.forEach((link) => {
  if ("children" in link && !!link.children) {
    flatHeaderLinks.push({ label: link.label, isDropdown: true });
    flatHeaderLinks = flatHeaderLinks.concat(
      link.children.map((child) => ({ ...child, isNested: true })),
    );
  } else {
    flatHeaderLinks.push(link);
  }
});

const useStyles = createStyles((theme) => ({
  main: {
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },

  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  link: {
    display: "flex",
    alignItems: "center",
    color: theme.colors.gray[0],
    fontWeight: 600,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("md")]: {
      padding: theme.spacing.md,
    },
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    backgroundColor: theme.colors.primary[4],
    left: 0,
    right: 0,
    zIndex: 1,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

const PublicHeader = () => {
  const { classes } = useStyles();
  const [opened, { toggle, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(links.header[0]!.label);

  return (
    <Header
      height={HEADER_HEIGHT}
      color="black"
      sx={(theme) => ({
        backgroundColor: theme.colors.primary[4],
      })}
    >
      <Container size="lg">
        <Flex
          align="center"
          justify="space-between"
          sx={{ height: HEADER_HEIGHT }}
        >
          <a href="https://designerstrust.com">
            <Image src="/images/DT_logo_by.svg" width={200} />
          </a>
          <Flex className={classes.links}>
            <Group spacing="lg">
              {links.header.map(({ label, ...rest }, index) =>
                "children" in rest && !!rest.children ? (
                  // rome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  <Menu key={index}>
                    <Menu.Target>
                      <Text className={classes.link}>
                        {label}{" "}
                        <IconCaretDown
                          size={14}
                          fill="white"
                          className="ml-1"
                        />
                      </Text>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {rest.children.map((nestedLink) => (
                        <Menu.Item
                          key={nestedLink.label}
                          // @ts-ignore
                          component={
                            "external" in nestedLink && nestedLink.external
                              ? "a"
                              : Link
                          }
                          // @ts-ignore
                          to={
                            "external" in nestedLink && !nestedLink.external
                              ? nestedLink.url
                              : undefined
                          }
                          href={
                            "external" in nestedLink && nestedLink.external
                              ? nestedLink.url
                              : undefined
                          }
                        >
                          {nestedLink.label}
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                ) : (
                  <Anchor
                    // rome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    className={classes.link}
                    // @ts-ignore
                    component={rest.external ? "a" : Link}
                    // @ts-ignore
                    to={!rest.external ? rest.url : undefined}
                    href={rest.external ? rest.url : undefined}
                  >
                    {label}
                  </Anchor>
                ),
              )}
            </Group>
            <Group ml="md">
              <Button color="green" component={Link} to="/register">
                Sign Up
              </Button>
              <Button variant="white" component={Link} to="/login">
                Login
              </Button>
            </Group>
          </Flex>
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            color="white"
          />
          <Transition
            transition="pop-top-right"
            duration={200}
            mounted={opened}
          >
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
                {flatHeaderLinks.map(({ label, ...rest }, index) => (
                  <Box
                    // rome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    className={classes.link}
                    sx={{ marginLeft: rest.isNested ? 16 : undefined }}
                    // @ts-ignore
                    component={
                      "children" in rest
                        ? "button"
                        : "external" in rest && rest.external
                          ? "a"
                          : Link
                    }
                    role={"children" in rest ? "button" : "link"}
                    // @ts-ignore
                    to={
                      "external" in rest && !rest.external
                        ? rest.url
                        : undefined
                    }
                    href={
                      "children" in rest
                        ? undefined
                        : "external" in rest && rest.external
                          ? rest.url
                          : undefined
                    }
                    onClick={(e) => {
                      if ("children" in rest) return;

                      e.preventDefault();
                      setActiveLink(label);
                      close();

                      if ("external" in rest && rest.external)
                        window.location.href = rest.url!;
                      else if ("external" in rest && !rest.external)
                        navigate(rest.url!);
                    }}
                  >
                    {rest.isDropdown ? (
                      <>
                        {label} <IconCaretDown size={14} fill="white" />
                      </>
                    ) : (
                      label
                    )}
                  </Box>
                ))}
              </Paper>
            )}
          </Transition>
        </Flex>
      </Container>
    </Header>
  );
};

export const PublicLayout = ({ children }: PropsWithChildren) => {
  const { classes } = useStyles();

  return (
    <AppShell
      fixed={false}
      header={<PublicHeader />}
      padding={0}
      classNames={{
        main: classes.main,
      }}
    >
      <BackgroundImage
        src="/images/bg-pattern.png"
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[1],
          backgroundRepeat: "repeat",
          height: "100%",
        })}
        p={{ base: 0, md: "md" }}
      >
        {children}
      </BackgroundImage>
    </AppShell>
  );
};
